<script lang="ts" setup>
import { useTimeoutFn } from '@vueuse/core'
import type { Component } from 'vue'

type Position = 'is-top-right' | 'is-top' | 'is-top-left' | 'is-bottom-right' | 'is-bottom' | 'is-bottom-left'

const props = withDefaults(
  defineProps<{
    type?: string
    message?: string
    duration?: number
    position?: Position
    component?: Component
    propsData?: Record<string, unknown>
  }>(),
  {
    type: 'is-success',
    duration: 2000,
    position: 'is-top-right',
  },
)

const isActive = ref(true)

const vm = getCurrentInstance()

const destroy = useTimeoutFn(
  () => {
    vm?.proxy?.$root?.$el.remove()
  },
  150,
  { immediate: false },
)

const close = () => {
  isActive.value = false
  // Timeout for the animation complete before destroying
  destroy.start()
}

useTimeoutFn(() => close(), props.duration)
</script>

<template>
  <Teleport to="#teleport-target-toast">
    <!-- NOTE: ここだけkebabケースでprops指定するとtsエラーとなってしまうための暫定対応 -->
    <!-- eslint-disable-next-line vue/attribute-hyphenation -->
    <Transition enterActiveClass="tw-animated tw-animate-fade-in" leaveActiveClass="tw-animated tw-animate-fade-out">
      <article v-show="isActive" class="notification" :class="[type, position]">
        <button class="delete" type="button" @click="close" />
        <Component :is="component" v-if="component" v-bind="propsData" />
        <span v-else v-text="message" />
      </article>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
@use '@/stylesheets/application/resources';

.notification {
  display: inline-flex;
  width: 400px;

  @include resources.mobile {
    margin: 0;
    width: 100%;
  }

  // NOTE: 現状is-top-right以外は利用していないが他のポジションに表示したくなった場合は以下を利用
  &.is-top,
  &.is-bottom {
    align-self: center;
  }

  &.is-top-right,
  &.is-bottom-right {
    align-self: flex-end;
  }

  &.is-top-left,
  &.is-bottom-left {
    align-self: flex-start;
  }
}
</style>
