import { isBackForwardNavigation } from '@/src/lib/browser'
import { showSuccess, showError } from '@/src/lib/toast'

export const displayFlashMessage = (): void => {
  if (isBackForwardNavigation()) return

  const { flash } = window.APP.rails
  if (!flash) return

  if (flash.notice) showSuccess(flash.notice)
  if (flash.alert) showError(flash.alert)
}
