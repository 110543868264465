// import Vue from 'vue'
import { h, createApp } from 'vue'
import ToastItem from '@/src/components/ToastItem.vue'
import ToastItemMessages from '@/src/components/ToastItemMessages.vue'

const open = (propsData: Record<string, any>) => {
  const el = document.createElement('div')

  const wrapper = createApp({
    name: 'VWrapper',
    render() {
      return h(ToastItem, propsData)
    },
  })

  wrapper.mount(el)
  document.body.append(el)
}

const showSuccess = (message: string): void => {
  open({
    message,
    type: 'is-success',
    position: 'is-top-right',
    duration: 10_000,
  })
}

const showError = (message: string): void => {
  open({
    message,
    type: 'is-danger',
    position: 'is-top-right',
    duration: 20_000,
  })
}

const showErrors = (messages: readonly string[]): void => {
  open({
    component: ToastItemMessages,
    propsData: { messages },
    type: 'is-danger',
    position: 'is-top-right',
    duration: 20_000,
  })
}

export { open, showSuccess, showError, showErrors }
