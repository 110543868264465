<template>
  <div class="content">
    <ul class="mt-0">
      <li v-for="(message, k) in messages" :key="k">
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    messages: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
})
</script>
