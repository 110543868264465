<template>
  <div id="teleport-target-toast" class="toast-portal is-top" />
</template>

<script lang="ts">
export default defineComponent({})
</script>

<style scoped lang="scss">
@use '@/stylesheets/application/resources';

.toast-portal {
  display: flex;
  inset: 0;
  overflow: hidden;
  padding: 2em;
  pointer-events: none;
  position: fixed;
  z-index: 1000;

  &.is-top {
    flex-direction: column;
  }

  // NOTE: 現状is-top以外は利用していないが他のポジションに表示したくなった場合は以下を利用
  // &.is-bottom
  //   flex-direction: column-reverse
  // &.has-custom-container
  //   position: absolute

  @include resources.mobile {
    padding: 1em !important;
    position: fixed !important;
  }
}
</style>
